<template>
  <div>

    <v-dialog :value='visible' persistent scrollable width='80vw' max-width='1200px'>
      <v-card>
        <v-card-title class='primary white--text'>Quality Management System</v-card-title>
        <v-card-text style='height: 80vh; max-height: 700px;' class='pa-4'>

          <v-simple-table dense>
            <thead><tr><th>Task</th><th>Delegate</th><th>Author</th><th>Date</th></tr></thead>
            <tbody>
              <tr v-for='a in activity' :key='a.xid'>
                <td>{{a.task.title}}</td>
                <td>{{a.delegate}}</td>
                <td>{{a.author.name_first}} {{a.author.name_last}}</td>
                <td><Moment :timestamp='a.created_at' mode='calendar' tooltip /></td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-btn x-small text class='primary--text' @click='assignTask'><v-icon small>mdi-plus</v-icon> Assign Task</v-btn>

        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn class='primary' @click='close'>Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <TaskAssigner ref='taskAssigner' @taskAdded='refreshActivity' />

  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
import TaskAssigner from './TaskAssigner'
import Moment from '@/components/Moment'
export default {
  name: 'QmsJobPopup',

  components: {
    TaskAssigner, Moment
  },

  props: {
    jobId: { type: String, default: null }
  },

  data: () => ({
    visible: false,
    activity: [
    ],
  }),

  computed: {
  },

  methods:{
    ...mapActions("API", ["API"]),

    open(){
      this.visible = true;
      this.refreshActivity();
    },

    close(){ this.visible = false; },

    refreshActivity(){
      this.$emit('taskAdded');
      this.activity = [];
      this.API({ method: 'GET', endpoint: `qms/activity/${this.jobId}` })
      .then(activity => { this.activity = activity })
    },

    assignTask(){ this.$refs.taskAssigner.open({ job: this.jobId }); },

  },

  mounted() {
  },

};
</script>
