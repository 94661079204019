<template>
  <div>
    <v-row>
      <v-col cols=12>
        <v-autocomplete outlined label="Add an item..." dense
          :items="lookupItems"
          :search-input.sync="lookupText"
          :loading="lookupLoading || loading"
          xitem-text = "label"
          v-model='lookupSelection'
          return-object
          auto-select-first
          hide-no-data
          @change="addLookupLineItem"
          :filter='lookupFilter'
          no-filter
          clearable
          ref='lookup'
          >
            <template #item="{item}">
              <v-chip small class='primary mr-2' v-if='item.tag'>{{item.tag}}</v-chip>
              <div style='width:100%;'>
                  {{item.title}}
                <div class='text-caption' v-if='item.description.length'>
                  {{item.description}}
                </div>
              </div>
              <div class='font-weight-medium float-right text-caption'>${{(item.price/100).toFixed(2)}}</div>
            </template>
        </v-autocomplete>
        {{lookupSelection}}
      </v-col>
    </v-row>
    <v-list ref='sortArea'>
      <v-list-item v-for='item in items' :key='item.xid' style='min-height:0px;' class='my-1 px-0' @click='clickEdit(item)'>
        <v-list-item-avatar size=20 class='my-0'><v-icon class='dragMe'>mdi-drag</v-icon></v-list-item-avatar>
        <v-list-item-content class='d-block py-0'>
          <span class='text-subtitle-2 black--text font-weight-medium mr-2'>{{item.title}}</span>
          <span class='text-caption secondary--text font-italic'>{{item.description}}</span>
        </v-list-item-content>
        <v-list-item-action class='my-0'>
          <div class='text-caption' style='position:relative;top:2px;'>
            <span class='mr-3'>{{item.qty}}x</span>
            <span class='mr-1'>${{(item.price/100).toFixed(2)}}<span v-if='item.tax'> *</span></span>

            <v-menu>
              <template v-slot:activator="{on,attrs}">
                <v-btn icon small v-on='on' v-bind='attrs'><v-icon>mdi-dots-vertical</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item link @click='clickEdit(item)'><v-icon class='mr-4 primary--text'>mdi-pencil</v-icon>Edit</v-list-item>
                <v-list-item link @click='clickDuplicate(item)'><v-icon class='mr-4 primary--text'>mdi-content-copy</v-icon>Duplicate</v-list-item>
                <v-list-item link @click='clickDelete(item)'><v-icon class='mr-4 primary--text'>mdi-trash-can-outline</v-icon>Delete</v-list-item>
              </v-list>
            </v-menu>
          </div>
        </v-list-item-action>

      </v-list-item>
    </v-list>

    <v-simple-table class='mt-2 float-right totalTable' style='width: 300px;' dense>
      <tbody>
        <tr v-if='hasTaxableItems'><td colspan=2 class='text-caption'>* Taxable Items</td></tr>
        <tr v-if='true'><td>Subtotal</td><td>${{subTotal}}</td></tr>
        <tr v-if='taxLabel'><td>{{taxLabel}}</td><td>${{taxApplicable}}</td></tr>
        <tr v-if='true'><td>Total</td><td>${{total}}</td></tr>
      </tbody>
    </v-simple-table>

    <LineitemEditor ref='editor' @update='itemUpdated' />

  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
.dragMe{
  cursor: move;
}
.editMenu{
  position:absolute;
  top:4px; right:0;
}
.totalTable{
  td{ text-align: right; }
  tr:last-of-type{ font-weight: bold; }
  tr:hover{ background-color: transparent !important; }
}
</style>

<script>
import { mapActions } from 'vuex'
import Sortable from 'sortablejs';
import LineitemEditor from './LineitemEditor'

export default {
  name: 'JobLineitems',

  components: {
    LineitemEditor,
  },

  props: {
    jobId: { type: String },
  },

  data: () => ({
    items: [],
    loading: false,
    lookupText: null,
    lookupLoading: false,
    lookupResults: [],
    lookupSelection: null,
    tax: null,
    defaultQty: 1,
  }),

  watch: {
    lookupText(lookup){
      if(!lookup) { this.lookupResults = []; return; }
      this.lookupLoading = true;
      return this.API({ method: "POST", endpoint: `jobs/${this.jobId}/lineitems/lookup`, autosave: 250, data: { lookup }})
      .then(items => { this.lookupResults = items; })
      .finally(() => { this.lookupLoading = false; });
    }
  },

  computed: {
    hasTaxableItems(){
      return this.items.findIndex(i => i.tax) != -1;
    },

    subTotal(){
      let subtotal =  this.items.reduce((subtotal, item) => subtotal + item.price * item.qty, 0);
      return (subtotal/100).toFixed(2);
    },

    taxApplicable(){
      let tax =  this.items.reduce((subtotal, item) => subtotal + (item.tax ? (item.price * item.qty * this.taxRate) : 0), 0);
      return (tax/100).toFixed(2);
    },

    taxRate(){
      return this.tax ? parseFloat(this.tax.rate)/100 : 0;
    },

    taxLabel(){
      return this.tax?.title ?? null;
    },

    total(){
      let total = parseFloat(this.subTotal) + parseFloat(this.taxApplicable);
      return total.toFixed(2);
    },

    lookupItems(){
      let items = this.lookupResults.map(item => ({ ...item, label: item.title, custom: false, }));
      if(this.lookupText)
        items.push({
          label: `+ "${this.lookupText}"`,
          title: this.lookupText,
          key: items.length,
          description: "", qty: 1, price: 0, tax: false, custom: true,
          tag: 'custom'
        });
      return items;
    },
  },

  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['PROMPT']),

    loadData(){
      this.loading = true;
      return this.API({ method: "GET", endpoint: `jobs/${this.jobId}/lineitems` })
      .then(data => {
        this.items = data.lineitems;
        this.tax = data.tax;
      })
      .finally(() => { this.loading = false; });
    },

    lookupFilter(item, queryText, itemText){
      let searchTerms = queryText.trim().toLowerCase().split(' ');
      let searchSpace = itemText.trim().toLowerCase();
      return searchTerms.every(term => searchSpace.includes(term));
    },

    makeSortable(){
      this.sortable = new Sortable(this.$refs.sortArea.$el, {
        animation: 100,
        handle: '.dragMe',
        swapThreshold: 1,
        //onEnd: this.updateOrder,
      });
    },

    addLookupLineItem(item){
      if(!item) return;
      let {title, description, price, tax} = item;
      let data = { title, description, price, tax, qty: this.defaultQty };
      this.$refs.lookup.reset();
      this.loading = true;
      this.API({ method: 'POST', endpoint: `jobs/${this.jobId}/lineitems`, data })
      .then(lineitem => {
        this.loadData()
        .then(() => { this.clickEdit(lineitem); });
      });
    },

    checkQuantity(item){
      this.PROMPT({ title: 'Quantity', message: 'Quantity for line item?', placeholder: item.qty })
      .then(qty => {
        this.defaultQty = qty;
        qty = parseFloat(qty);
        this.loading = true;
        let data = { qty };
        this.API({ method: 'PATCH', endpoint: `lineitems/${item.xid}`, data })
        .then(this.loadData)
        .finally(() => { this.loading = false; });
      });
    },

    clickEdit(item){
      this.$refs.editor.open(item);
    },

    itemUpdated(item){
      this.defaultQty = item.qty;
      this.loadData();
      this.$nextTick(() => { this.$refs.lookup.focus() });
    },

    clickDuplicate(item){
      this.loading = true;
      this.API({ method: 'POST', endpoint: `lineitems/${item.xid}/duplicate` })
      .then(this.loadData)
      .finally(() => { this.loading = false; });
    },

    clickDelete(item){
      this.loading = true;
      this.API({ method: 'DELETE', endpoint: `lineitems/${item.xid}` })
      .then(this.loadData)
      .finally(() => { this.loading = false; });
    },

  },

  mounted() {
    this.loadData()
    this.$nextTick(this.makeSortable);
  },

};
</script>
