<template>
  <v-row class='mb-2'>
    <v-col cols=12>
      <v-textarea :counter="hasText" :hide-details="!hasText" outlined auto-grow label="Write a note..." rows="1" v-model="text" maxlength="500" :disabled='loading' :color='colour' dense />

      <v-row v-if="hasText" style='position:relative;top:-10px;'>
        <v-col cols=4>
          <v-btn block :color='colour' :disabled='loading' :loading='loading' @click="addNote">Add Note</v-btn>
        </v-col>

        <v-col cols=6>
          <v-btn-toggle v-if='hasText' style='position:relative;' v-model='colour' mandatory>
            <v-btn
              v-for="c in colours" v-bind:key='c'
              :color='c' icon
              :value='c'
              :disabled="loading"
              style='height:38px;width:38px;padding:0px;'
            ><v-icon :color='c' :small='colour != c' >mdi-circle</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-col>

      </v-row>

    </v-col>
  </v-row>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NewJobNote',

  components: {
  },

  props: {
    jobId: { type: String },
  },

  data: () => ({
    text: "",
    colour: "primary",
    colours: ['primary', 'warning', 'error', 'info'],
    loading: false,
  }),

  computed: {
    hasText(){
      return this.text != "";
    }
  },

  methods:{
    ...mapActions('API', ['API']),

    addNote(){
      this.loading = true;
      this.API({ method: 'POST', endpoint: `jobHistory/${this.jobId}/addNote`, data: {
        colour: this.colour,
        text: this.text
      }})
      .then(() => {
        this.text = "";
        this.colour="primary";
        this.$emit('newNote');
      })
      .finally(() => { this.loading = false; });
    }

  },

  mounted() {
  },

};
</script>
