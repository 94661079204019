var render = function () {
  var _vm$Package, _vm$Package$to_addres;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.isOpen,
      "max-width": "600",
      "scrollable": "",
      "persistent": ""
    }
  }, [_vm.Package != null ? _c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Package ")]), _c('v-card-text', {
    staticClass: "pt-4",
    staticStyle: {
      "max-height": "80vh",
      "height": "auto"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12 pb-0 text-caption"
    }
  }, [_vm._v(" Destination "), _c('v-divider', {
    staticClass: "pb-0"
  })], 1), _c('v-col', {
    staticClass: "secondary--text",
    staticStyle: {
      "white-space": "pre-wrap"
    },
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(_vm._s((_vm$Package = _vm.Package) === null || _vm$Package === void 0 ? void 0 : (_vm$Package$to_addres = _vm$Package.to_address) === null || _vm$Package$to_addres === void 0 ? void 0 : _vm$Package$to_addres.address))]), _c('v-col', {
    attrs: {
      "cols": "12 pb-0 text-caption"
    }
  }, [_vm._v(" Package "), _c('v-divider', {
    staticClass: "pb-0"
  })], 1), _c('v-col', {
    staticClass: "secondary--text",
    attrs: {
      "cols": "12"
    }
  }, [_vm.Package.description !== null ? _c('span', [_vm._v(_vm._s(_vm.Package.description)), _c('br')]) : _vm._e(), _vm._v(" " + _vm._s(_vm.Package.length) + "cm x " + _vm._s(_vm.Package.width) + "cm x " + _vm._s(_vm.Package.height) + "cm "), _vm.Package.weight !== null ? _c('span', [_vm._v(", " + _vm._s(_vm.Package.weight) + "kg")]) : _vm._e()]), _c('v-col', {
    attrs: {
      "cols": "12 pb-0 text-caption"
    }
  }, [_vm._v(" Label "), _c('v-divider', {
    staticClass: "pb-0"
  })], 1), _vm.Package.courier !== null ? _c('v-col', {
    staticClass: "secondary--text mb-0 pb-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.Package.courier.label))])]) : _vm._e(), _vm.Package.tracking_number !== null ? _c('v-col', {
    staticClass: "secondary--text mt-0 pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.Package.tracking_number))])]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12 pb-0 text-caption"
    }
  }, [_vm._v(" Jobs "), _c('v-divider', {
    staticClass: "pb-0"
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, _vm._l(_vm.Package.jobs, function (j) {
    return _c('v-chip', {
      key: j.xid,
      staticClass: "primary mr-1 mb-1",
      attrs: {
        "label": "",
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(j.ref) + " ")]);
  }), 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "grey lighten-4"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.printLabel
    }
  }, [_vm._v("Print Label")]), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.isOpen = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }