var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    ref: "lookup",
    attrs: {
      "outlined": "",
      "label": "Add an item...",
      "dense": "",
      "items": _vm.lookupItems,
      "search-input": _vm.lookupText,
      "loading": _vm.lookupLoading || _vm.loading,
      "xitem-text": "label",
      "return-object": "",
      "auto-select-first": "",
      "hide-no-data": "",
      "filter": _vm.lookupFilter,
      "no-filter": "",
      "clearable": ""
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.lookupText = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.lookupText = $event;
      },
      "change": _vm.addLookupLineItem
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.tag ? _c('v-chip', {
          staticClass: "primary mr-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v(_vm._s(item.tag))]) : _vm._e(), _c('div', {
          staticStyle: {
            "width": "100%"
          }
        }, [_vm._v(" " + _vm._s(item.title) + " "), item.description.length ? _c('div', {
          staticClass: "text-caption"
        }, [_vm._v(" " + _vm._s(item.description) + " ")]) : _vm._e()]), _c('div', {
          staticClass: "font-weight-medium float-right text-caption"
        }, [_vm._v("$" + _vm._s((item.price / 100).toFixed(2)))])];
      }
    }]),
    model: {
      value: _vm.lookupSelection,
      callback: function callback($$v) {
        _vm.lookupSelection = $$v;
      },
      expression: "lookupSelection"
    }
  }), _vm._v(" " + _vm._s(_vm.lookupSelection) + " ")], 1)], 1), _c('v-list', {
    ref: "sortArea"
  }, _vm._l(_vm.items, function (item) {
    return _c('v-list-item', {
      key: item.xid,
      staticClass: "my-1 px-0",
      staticStyle: {
        "min-height": "0px"
      },
      on: {
        "click": function click($event) {
          return _vm.clickEdit(item);
        }
      }
    }, [_c('v-list-item-avatar', {
      staticClass: "my-0",
      attrs: {
        "size": "20"
      }
    }, [_c('v-icon', {
      staticClass: "dragMe"
    }, [_vm._v("mdi-drag")])], 1), _c('v-list-item-content', {
      staticClass: "d-block py-0"
    }, [_c('span', {
      staticClass: "text-subtitle-2 black--text font-weight-medium mr-2"
    }, [_vm._v(_vm._s(item.title))]), _c('span', {
      staticClass: "text-caption secondary--text font-italic"
    }, [_vm._v(_vm._s(item.description))])]), _c('v-list-item-action', {
      staticClass: "my-0"
    }, [_c('div', {
      staticClass: "text-caption",
      staticStyle: {
        "position": "relative",
        "top": "2px"
      }
    }, [_c('span', {
      staticClass: "mr-3"
    }, [_vm._v(_vm._s(item.qty) + "x")]), _c('span', {
      staticClass: "mr-1"
    }, [_vm._v("$" + _vm._s((item.price / 100).toFixed(2))), item.tax ? _c('span', [_vm._v(" *")]) : _vm._e()]), _c('v-menu', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref2) {
          var on = _ref2.on,
            attrs = _ref2.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "icon": "",
              "small": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.clickEdit(item);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4 primary--text"
    }, [_vm._v("mdi-pencil")]), _vm._v("Edit")], 1), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.clickDuplicate(item);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4 primary--text"
    }, [_vm._v("mdi-content-copy")]), _vm._v("Duplicate")], 1), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function click($event) {
          return _vm.clickDelete(item);
        }
      }
    }, [_c('v-icon', {
      staticClass: "mr-4 primary--text"
    }, [_vm._v("mdi-trash-can-outline")]), _vm._v("Delete")], 1)], 1)], 1)], 1)])], 1);
  }), 1), _c('v-simple-table', {
    staticClass: "mt-2 float-right totalTable",
    staticStyle: {
      "width": "300px"
    },
    attrs: {
      "dense": ""
    }
  }, [_c('tbody', [_vm.hasTaxableItems ? _c('tr', [_c('td', {
    staticClass: "text-caption",
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("* Taxable Items")])]) : _vm._e(), true ? _c('tr', [_c('td', [_vm._v("Subtotal")]), _c('td', [_vm._v("$" + _vm._s(_vm.subTotal))])]) : _vm._e(), _vm.taxLabel ? _c('tr', [_c('td', [_vm._v(_vm._s(_vm.taxLabel))]), _c('td', [_vm._v("$" + _vm._s(_vm.taxApplicable))])]) : _vm._e(), true ? _c('tr', [_c('td', [_vm._v("Total")]), _c('td', [_vm._v("$" + _vm._s(_vm.total))])]) : _vm._e()])]), _c('LineitemEditor', {
    ref: "editor",
    on: {
      "update": _vm.itemUpdated
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }