<template>
  <v-timeline-item :color="colour" small>
      <ItemHeader :timestamp="timestamp" :user="user" />
      <template v-if="status">
        <span class='text-caption'> changed the job status to </span>
        <v-tooltip bottom>
        <template v-slot:activator="{on}">
          <v-chip v-on="on" small label :color="colour" class='px-2 white--text font-weight-bold'>{{ status }}</v-chip>
        </template>
        <span v-if='description === "" ' class='font-italic'>No description</span>
        <span v-else>{{description}}</span>
      </v-tooltip>

      </template>
      <span v-else class='text-caption'> removed the job status</span>
  </v-timeline-item>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>

import ItemHeader from './ItemHeader'

export default {
  name: 'Status',

  components: {
    ItemHeader,
  },

  props: {
    data: { required: true },
    timestamp: { type: String, required: true },
    user: { type: Object, required: true },
  },

  data: () => ({
  }),

  computed: {
    status(){
      return this.data?.status?.name || "";
    },

    colour(){
      return this.data?.status?.settings?.colour || "secondary";
    },

    description(){
      return this.data?.status?.settings?.description || "";
    },

  },

  methods:{
  },

  mounted() {
  },

};
</script>
