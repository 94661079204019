<template>
  <v-timeline-item :color="colour" small>
      <ItemHeader :timestamp="timestamp" :user="user" />
      <template v-if="customer">
        <span class='text-caption'> changed the customer to </span>
        <a @click="NAVIGATE('/customer/'+customer.xid)" class='text-caption font-weight-medium'>{{ customer.name }}</a>
      </template>
      <span v-else class='text-caption'> removed the customer</span>
  </v-timeline-item>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
a{
  &:hover{
      xtext-decoration: underline;
  }
}
</style>

<script>
import { mapActions } from 'vuex'
import ItemHeader from './ItemHeader'

export default {
  name: 'Customer',

  components: {
    ItemHeader,
  },

  props: {
    data: { required: true },
    timestamp: { type: String, required: true },
    user: { type: Object, required: true },
  },

  data: () => ({
  }),

  computed: {

    customer(){
      return this.data?.customer || "";
    },

    colour(){
      return "primary";
    }

  },

  methods:{
    ...mapActions("UI", ["NAVIGATE"]),
  },

  mounted() {
  },

};
</script>
