<template>
  <v-dialog v-model="show" v-if='show' max-width="500" persistent>
    <v-card elevation="20" :disabled='loading'>
      <v-form @submit.prevent='update'>
        <v-card-title class="text-h6 primary white--text py-3">
          Update Line Item
        </v-card-title>
        <v-card-text class='pt-8 text-subtitle-2' style='max-height:50vh;overflow:auto;'>
          <v-row>
            <v-col cols=12><v-text-field v-model='lineitem.title' label='Title' outlined dense hide-details /></v-col>
            <v-col cols=12 sm=5><v-text-field v-model='lineitem.qty' label='Qty' autofocus outlined dense hide-details /></v-col>
            <v-col cols=12 sm=5><v-text-field v-model='lineitem.price' label='Price' outlined dense hide-details /></v-col>
            <v-col cols=12 sm=2><v-checkbox v-model="lineitem.tax" label='Tax' hide-details class='mt-1'></v-checkbox></v-col>
            <v-col cols=12>
              <v-textarea
                class='pa-1' outlined hide-details auto-grow
                rows=1 label="Description"
                :value='lineitem.description' @change='lineitem.description = $event'
                />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class='pb-4'>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" @click='close'>Cancel</v-btn>
          <v-btn color="primary darken-1" type='submit'>Update</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'LineitemEditor',

  components: {
  },

  data: () => ({
    loading: false,
    show: false,
    lineitem: null,
  }),

  computed: {
  },

  methods:{
    ...mapActions("API", ["API"]),

    open(lineitem){
      this.lineitem = JSON.parse(JSON.stringify(lineitem)); // copy values
      this.lineitem.price /= 100;
      this.loading = false;
      this.show = true;
    },

    update(){
      this.loading = true;
      let data = { ...this.lineitem, price: this.lineitem.price * 100, };
      this.API({ method: "PATCH", endpoint: `lineitems/${this.lineitem.xid}`, data })
      .then(()=>{
        this.show = false;
        this.$emit('update', data);
      })
      .finally(() => { this.loading = false; });
    },

    close(){
      this.show = false;
    },



  },

  mounted() {
  },

};
</script>
