var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.history.length ? _c('v-timeline', {
    staticClass: "mb-3",
    attrs: {
      "dense": ""
    }
  }, _vm._l(_vm.history, function (item) {
    return _c('div', {
      key: item.xid
    }, [item.type == 'JobHistoryStatus' ? _c('Status', {
      attrs: {
        "data": item.data,
        "timestamp": item.created_at,
        "user": item.user
      }
    }) : _vm._e(), item.type == 'JobHistoryNote' ? _c('Note', {
      attrs: {
        "data": item.data,
        "timestamp": item.created_at,
        "user": item.user
      }
    }) : _vm._e(), item.type == 'JobHistoryCustomer' ? _c('Customer', {
      attrs: {
        "data": item.data,
        "timestamp": item.created_at,
        "user": item.user
      }
    }) : _vm._e(), item.type == 'JobHistoryContact' ? _c('Contact', {
      attrs: {
        "data": item.data,
        "timestamp": item.created_at,
        "user": item.user
      }
    }) : _vm._e(), item.type == 'JHDeliveryLocation' ? _c('DeliveryLocation', {
      attrs: {
        "data": item.data,
        "timestamp": item.created_at,
        "user": item.user
      }
    }) : _vm._e(), item.type == 'Activity' ? _c('Activity', {
      attrs: {
        "data": item.data,
        "timestamp": item.created_at,
        "user": item.user
      }
    }) : _vm._e(), item.type == 'JobHistoryDespatch' ? _c('DespatchPackage', {
      attrs: {
        "data": item.data,
        "timestamp": item.created_at,
        "user": item.user
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }