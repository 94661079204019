<template>
  <v-timeline-item :color="data.colour" small>
      <v-card>
        <v-card-title class='py-2 white--text' :class='data.colour'>
          <ItemHeader :timestamp="timestamp" :user="user"/>
        </v-card-title>
        <v-card-text class='my-0 py-4 black--text white' style='white-space:pre-wrap;'>
          {{data.text}}
        </v-card-text>
      </v-card>
  </v-timeline-item>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>

import ItemHeader from './ItemHeader'

export default {
  name: 'Note',

  components: {
    ItemHeader,
  },

  props: {
    data: { type: Object, required: true },
    timestamp: { type: String, required: true },
    user: { type: Object, required: true },
  },

  data: () => ({
  }),

  computed: {
    content(){
      return this.data?.content || "";
    },

    colour(){
      if(this.status == 'Completed') return "blue";
      if(this.status == 'Hold') return "warning";
      return "primary";
    }
  },

  methods:{
  },

  mounted() {
  },

};
</script>
