<template>
  <v-timeline-item :color="colour" small>
      <ItemHeader :timestamp="timestamp" :user="user" />
      <span class='text-caption'> despatched this job</span>
      
      <template v-if="_package">
        <span class='text-caption'>s </span>
        <a class='text-caption font-weight-medium' @click="viewPackage(_package)"> package</a>
        <br/>
        <div class="d-flex align-center" v-if="_package.tracking_number">
          <v-btn icon v-if="_package.tracking_url" class="overflow-hidden d-block mr-2 pa-1" large @click.stop="clickTrackingLink(_package)">
            <v-img :src="_package.courier._17track_courier.logo" contain style="mix-blend-mode: multiply;" class="rounded-xl" />
          </v-btn>
          <a class='text-caption font-weight-medium' @click="copyToClipboard(_package.tracking_number)">{{ _package.tracking_number }}</a>
        </div>

      </template>
      <ViewPackageDialog ref='viewPackageDialog' />
  </v-timeline-item>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
import ItemHeader from './ItemHeader'
import ViewPackageDialog from '@/components/Dispatch/ViewPackageDialog.vue';

export default {
  name: 'DeliveryLocation',

  components: {
    ItemHeader, ViewPackageDialog
  },

  props: {
    data: { required: true },
    timestamp: { type: String, required: true },
    user: { type: Object, required: true },
  },

  data: () => ({
    colour: "primary"
  }),

  computed: {

    _package(){
      return this.data?.package || null;
    },

  },

  methods:{
    ...mapActions("UI", ["NAVIGATE", "ALERT", "NOTIFY"]),

    copyToClipboard(text){
      navigator.clipboard.writeText(text);
      this.NOTIFY({ message: `📋 Copied to Clipboard.` })
    },

    clickTrackingLink(Package){
      window.open(Package.tracking_url, '_blank');
    },

    viewPackage(p){
      this.$refs.viewPackageDialog.edit(p);
    },

  },

  mounted() {
  },

};
</script>
