<template>
  <v-timeline-item :color="colour" small>
      <ItemHeader :timestamp="timestamp" :user="user" />
      <template v-if="staff_user">
        <span class='text-caption'> set the contact as </span>
        <span class='text-caption font-weight-medium'>{{name}}</span>
      </template>
      <span v-else class='text-caption'> removed the customer contact</span>
  </v-timeline-item>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
a{
  &:hover{
      xtext-decoration: underline;
  }
}
</style>

<script>
import { mapActions } from 'vuex'
import ItemHeader from './ItemHeader'

export default {
  name: 'Contact',

  components: {
    ItemHeader,
  },

  props: {
    data: { required: true },
    timestamp: { type: String, required: true },
    user: { type: Object, required: true },
  },

  data: () => ({
    colour: "primary"
  }),

  computed: {

    staff(){
      return this.data?.staff || null;
    },

    staff_user(){
      return this.staff?.user || null;
    },

    name(){
      if(!this.staff_user) return null;
      return `${this.staff_user.name_first} ${this.staff_user.name_last}`;
    }

  },

  methods:{
    ...mapActions("UI", ["NAVIGATE"]),
  },

  mounted() {
  },

};
</script>
