var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "text-caption"
  }, [_c('Moment', {
    attrs: {
      "timestamp": _vm.timestamp,
      "mode": "calendar",
      "tooltip": "",
      "tooltip-type": "datetime"
    }
  }), _vm.user ? _c('span', [_c('span', [_vm._v(" - ")]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('span', _vm._g({
          staticClass: "font-weight-medium"
        }, on), [_vm._v(_vm._s(_vm.user.name_first) + " " + _vm._s(_vm.user.name_last))])];
      }
    }], null, false, 2754218304)
  }, [_vm._v(" " + _vm._s(_vm.user.username) + " ")])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }