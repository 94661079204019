import { render, staticRenderFns } from "./LazyDropdown.vue?vue&type=template&id=26e87b12&"
import script from "./LazyDropdown.vue?vue&type=script&lang=js&"
export * from "./LazyDropdown.vue?vue&type=script&lang=js&"
import style0 from "./LazyDropdown.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
installComponents(component, {VAutocomplete})
