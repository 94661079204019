var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-autocomplete', {
    staticClass: "py-0 my-0",
    attrs: {
      "items": _vm.listItems,
      "loading": _vm.loading ? 'white' : false,
      "item-text": _vm.itemText,
      "item-value": _vm.itemValue,
      "return-object": "",
      "clearable": _vm.clearable,
      "hide-details": "",
      "outlined": _vm.outlined,
      "dense": _vm.dense,
      "placeholder": _vm.placeholder,
      "label": _vm.label
    },
    on: {
      "change": _vm.update
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._t("selection", [_vm._v(_vm._s(typeof _vm.itemText === 'function' ? _vm.itemText(item) : item[_vm.itemText]))], null, {
          item: item
        })];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._t("item", [_vm._v(_vm._s(typeof _vm.itemText === 'function' ? _vm.itemText(item) : item[_vm.itemText]))], null, {
          item: item
        })];
      }
    }], null, true),
    model: {
      value: _vm.model,
      callback: function callback($$v) {
        _vm.model = $$v;
      },
      expression: "model"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }