<template>

    <v-container v-if='LOGGED_IN' id='page'>

      <!-- TOP CARD -->
      <v-row justify='center'>
        <v-col cols=12 xl=10>

          <v-card :loading="loading?'white':false" :disabled="loading">

            <v-card-title class="primary white--text pt-0 pb-1">
              <v-row class='my-0'>

                <!-- Job Reference & Description -->
                <v-col cols=6 class='pb-0'>
                  <v-input prepend-icon="mdi-briefcase-variant" hide-details dark>
                    <div class='ma-0' style='width:100%'>
                      <span>{{jobref}}</span>
                      <span v-if="description && !editDetails">: {{ description }}</span>
                      <v-text-field v-if="editDetails" dense block :value="description" placeholder="Job Description" @change="val=>{ description = val; }" :loading='loading?"white":false' counter maxlength="255" />
                      <LazyDropdown v-if="editDetails" url="job_types" v-model="type" placeholder="Job Type" item-text="name" item-value="xid" />
                      <div class="text-caption">{{ type?.name }}</div>
                    </div>
                  </v-input>
                </v-col>

                <!-- Edit Details Button -->
                <v-col cols=6 class='pt-2 pr-0 pb-0'>
                  <div>
                  <v-btn fab small dark text class='float-right' @click='editDetails=!editDetails'><v-icon>{{editDetails?'mdi-lock-open':'mdi-lock'}}</v-icon></v-btn>
                  <v-btn fab small dark text class='float-right' @click='printLabel' title='Print Job Label'><v-icon>mdi-tag-text</v-icon></v-btn>
                  <v-btn fab small dark text class='float-right' @click='clickQMS' title='Quality Management System'><v-icon>mdi-medal-outline</v-icon></v-btn>
                  <v-btn fab small dark text class='float-right' @click='completeJob' title='Complete Job'><v-icon>mdi-file-check-outline</v-icon></v-btn>
                  </div>
                </v-col>

              </v-row>
            </v-card-title>

            <v-card-text class='xprimary xwhite--text text-body-1 py-0'>
              <v-row class='my-0'>

                <!-- Customer Selection -->
                <v-col cols=12 md=3>
                  <v-input hide-details>
                    <template v-slot:prepend><v-icon color='primary'>mdi-office-building</v-icon></template>
                    <template v-if="editDetails == false">
                      <span v-if="customer" @click="NAVIGATE('/customer/'+customer.xid)" class='text-button text-truncate' style='max-width:100%;cursor:pointer;'>{{customer.name}}</span>
                      <span v-else class='text-button text-truncate grey--text' style='max-width:100%;'>Customer</span>
                    </template><template v-else>
                      <LazyDropdown url="customers" v-model="customer" placeholder="Customer..." item-text="name" item-value="xid" />
                    </template>
                  </v-input>
                </v-col>

                <!-- Customer Contact / Clinician -->
                <v-col cols=12 md=3>
                  <v-input hide-details>
                    <template v-slot:prepend><v-icon color='primary'>mdi-account-circle</v-icon></template>
                    <div class="">
                      <template v-if="editDetails == false">
                        <div v-if="contact" text  class='text-button text-truncate' style='max-width:100%;'>{{contact.user.name_first}} {{contact.user.name_last}}</div>
                        <span v-else class='text-button text-truncate grey--text' style='max-width:100%;'>Contact</span>
                      </template>
                      <template v-else>
                        <LazyDropdown v-if='customer' :url="'/customers/'+customer.xid+'/staff'" v-model="contact" placeholder="Contact" :item-text="({user})=>`${user.name_first} ${user.name_last}`" xitem-value="xid" />
                      </template>
                    
                      <div class="text-caption warning--text" v-if="contactErrorMessage">{{ contactErrorMessage }}</div>
                    </div>
                  </v-input>
                </v-col>

                <!-- Delivery Address -->
                <v-col cols=12 md=3>
                  <v-input hide-details>
                    <template v-slot:prepend><v-icon color='primary'>mdi-office-building-marker</v-icon></template>
                    <template v-if="editDetails == false">
                      <span v-if="ship_to" text class='text-button text-truncate' style='max-width:100%;'>

                        <v-tooltip v-if="true" bottom>
                          <template #activator="{on,attrs}">
                            <span v-on='on' v-bind='attrs'>{{ship_to.company_name}}</span>
                          </template>
                          {{ship_to.address}}
                        </v-tooltip>
                      </span>
                      <span v-else class='text-button text-truncate grey--text' style='max-width:100%;'>Delivery Address</span>
                    </template><template v-else>
                      <LazyDropdown v-if='customer' :url="'/customers/'+customer.xid+'/locations'" v-model="ship_to" placeholder="Delivery Address" item-text="company_name" item-value="xid">
                        <template #item="{item}">
                          <div>
                            <div>{{ item.company_name }}</div>
                            <div class="text-caption secondary--text">{{ [item.address_line_1, item.address_line_2, item.address_line_3].filter(v=>v).join(', ') }}</div>
                            <div class="text-caption secondary--text">{{ [item.city_locality, item.state_province, item.postal_code].filter(v=>v).join(' ') }}</div>
                          </div>
                        </template>
                      </LazyDropdown>
                    </template>

                  </v-input>
                </v-col>

                <!-- Due Date -->
                <v-col cols=12 md=3>
                  <v-input hide-details>
                    <template v-slot:prepend><v-icon color='primary'>mdi-calendar</v-icon></template>
                    <template v-if="editDetails == false">
                      <span v-if="due_date" text  class='text-button text-truncate' style='max-width:100%;'>
                        <Moment :timestamp='due_date' xmode='calendar' tooltip />
                      </span>
                      <span v-else class='text-button text-truncate grey--text' style='max-width:100%;'>Due Date</span>
                    </template><template v-else>
                      <v-menu
                        v-model="dueDatePickerMenu"
                        :close-on-content-click="false"
                        xtransition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            hide-details dense readonly clearable v-bind="attrs" v-on="on"
                            v-model="due_date"
                            placeholder="Due Date"
                            @click:clear="due_date = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="due_date" @input="dueDatePickerMenu = false" />
                      </v-menu>
                    </template>
                  </v-input>
                </v-col>

                <!-- Job Status -->
                <v-col cols=12 md=3>
                  <v-input hide-details>
                    <template v-slot:prepend><v-icon color='primary'>mdi-state-machine</v-icon></template>
                    <template v-if="editDetails == false">
                      <span v-if="status" text  class='text-button text-truncate' style='max-width:100%;'>{{status.name}}</span>
                      <span v-else class='text-button text-truncate grey--text' style='max-width:100%;'>Status</span>
                    </template><template v-else>
                      <LazyDropdown :url="'/jobStatus'" v-model="status" placeholder="Status" item-text="name" item-value="xid" />
                    </template>
                  </v-input>
                </v-col>

                <!-- Recent Activity -->
                <v-col cols=12 md=3>
                  <v-input hide-details>
                    <template v-slot:prepend><v-icon color='primary'>mdi-progress-star</v-icon></template>
                    <div :class="{ 'secondary--text': editDetails }">
                      <v-tooltip v-if="recent_activity" bottom>
                        <template #activator="{on,attrs}">
                          <span v-on='on' v-bind="attrs" class='text-button text-truncate' style='max-width:100%;'>{{recent_activity.task.title}}</span>
                        </template>
                        <Moment :timestamp='recent_activity.created_at' mode='timeAgo' />
                        &nbsp;by {{recent_activity.delegate}}
                      </v-tooltip>
                      <span v-else class='text-button text-truncate grey--text' style='max-width:100%;'>No Recent Activity</span>
                    </div>
                  </v-input>
                </v-col>

                <!-- Dispatch Status -->
                <v-col cols=12 md=3>
                  <v-input hide-details>
                    <template v-slot:prepend><v-icon color='primary'>mdi-truck</v-icon></template>
                    <template v-if="editDetails == false">
                      <span v-if="dispatch_status" text  class='text-button text-truncate' style='max-width:100%;'>{{dispatch_status.status}}</span>
                      <span v-else class='text-button text-truncate grey--text' style='max-width:100%;'>Dispatch Status</span>
                    </template><template v-else>
                      <LazyDropdown :url="'/dispatch/status'" v-model="dispatch_status" placeholder="Status" item-text="status" item-value="xid" :clearable='false' />
                    </template>
                  </v-input>
                </v-col>

                <!-- Payment Status -->
                <v-col cols=12 md=3>
                  <v-input hide-details>
                    <template v-slot:prepend><v-icon color='primary'>mdi-currency-usd</v-icon></template>
                    <template v-if="editDetails == false">
                      <span v-if="payment_status" text  class='text-button text-truncate' style='max-width:100%;'>{{payment_status.status}}</span>
                      <span v-else class='text-button text-truncate grey--text' style='max-width:100%;'>Payment Status</span>
                    </template><template v-else>
                      <LazyDropdown :url="'/billing/status'" v-model="payment_status" placeholder="Status" item-text="status" item-value="xid" :clearable='false' />
                    </template>
                  </v-input>
                </v-col>



              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- Main Tabbed Card -->
      <v-row justify='center' class='mb-2'>
        <v-col cols=12 xl=10>
          <v-card>
            <!-- Tabs Section -->
            <v-card-title class='primary py-1 white--text text-h6'>
              <v-icon class='mr-2 white--text' small>mdi-paperclip</v-icon>Files
            </v-card-title>
            <v-row class='mt-0'>
              <v-col class='pa-0' cols=12>
                <jobFiles
                  :jobId='jobId' ref='jobFiles'
                  @updateJobDetails='importDetails'
                  @updateLineItems='refreshLineItems'
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- Main Tabbed Card -->
      <v-row justify='center' class='mb-2'>
        <v-col cols=12 xl=10>
          <v-card>
            <!-- Tabs Section -->
            <v-card-title class='primary py-1 white--text text-h6'><v-icon class='mr-2 white--text' small>mdi-clipboard-text</v-icon>Details</v-card-title>
            <v-row class='mt-2 pb-4'>
              <v-col cols=12 lg=6 class='px-8'>
                <NewJobNote :jobId="jobId" @newNote="refreshHistory"/>
                <JobHistory :jobId="jobId" ref='history'/>
              </v-col>
              <v-col cols=12 lg=6 class='px-8'>
                <JobLineitems :jobId="jobId" ref='lineitems' />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog :value='importDetailsForm.data' persistent max-width='800px' scrollable>
        <v-card v-if='importDetailsForm.data' :disabled='importDetailsForm.loading'>
          <v-card-title class='primary white--text'>Import Job Details</v-card-title>
          <v-card-text class="">

            <v-row class="mt-0"> <!-- Headers -->
              <v-col cols="6" class="pb-0">
                <span class="primary--text font-weight-medium">Job Details</span>
              </v-col>
              <v-col cols="6" class="pb-0">
                <span class="primary--text font-weight-medium">Prescription Details</span>
              </v-col>
            </v-row>

            <v-row class="mt-2"> <!-- Description -->
              <v-col cols="6">
                <v-text-field outlined dense class='mt-2' hide-details :value="importDetailsForm.description" label="Description" @change="val=>{ importDetailsForm.description = val; }" />
                <LazyDropdown outlined dense  class='mt-4' url="job_types" v-model="importDetailsForm.job_type" label="Job Type" item-text="name" item-value="xid" />
              </v-col>
              <v-col cols="6">
                <v-text-field class="mt-2" hide-details outlined label="Prescription #" disabled dense :value="importDetailsForm.data.lasercam_job_number" />
                <v-text-field class="mt-4" hide-details outlined disabled label="Client" dense :value="importDetailsForm.data.clientName" />
              </v-col>
            </v-row>

            <v-row class="mt-0"> <!-- Customer -->
              <v-col cols="6" class=""> 
                <LazyDropdown outlined dense  class='mt-2' url="customers" v-model="importDetailsForm.customer" label="Customer" item-text="name" item-value="xid" @input='importDetails_changeCustomer' />
              </v-col>
              <v-col cols="6">
                <v-text-field class="mt-2" hide-details outlined disabled label="Clinic" dense :value="importDetailsForm.data.clinicName" />
              </v-col>
            </v-row>

            <v-row v-if="importDetailsForm.customer" class="mt-0">
              <v-col cols="6" class="pt-0">
                <LazyDropdown outlined dense class='mt-4' :url="'/customers/'+importDetailsForm.customer.xid+'/locations'" v-model="importDetailsForm.ship_to" label="Delivery Address" item-text="company_name" item-value="xid">
                    <template #item="{item}">
                      <div>
                        <div>{{ item.company_name }}</div>
                        <div class="text-caption secondary--text">{{ [item.address_line_1, item.address_line_2, item.address_line_3].filter(v=>v).join(', ') }}</div>
                        <div class="text-caption secondary--text">{{ [item.city_locality, item.state_province, item.postal_code].filter(v=>v).join(' ') }}</div>
                      </div>
                    </template>
                  </LazyDropdown>
                <LazyDropdown outlined dense class='mt-4' :url="'/customers/'+importDetailsForm.customer.xid+'/staff'" v-model="importDetailsForm.contact" label="Contact" :item-text="({user})=>`${user.name_first} ${user.name_last}`" />
              </v-col>
              <v-col cols="6" class="pt-0">
                <v-text-field class="mt-4" hide-details outlined disabled dense label="Address" :value="importDetailsForm.data.clinicAddress" />
                <v-text-field class="mt-4" hide-details outlined disabled dense label="Clinician" :value="importDetailsForm.data.clinicianName" />
              </v-col>
            </v-row>

            <v-row class="mt-0">
              <v-col cols="6">
                <v-menu
                  offset-y min-width="auto" max-width="290px"
                  v-model="importDetailsForm.due_date_popup"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      class='mt-2' readonly clearable outlined dense label="Due Date" hide-details
                      :value="formatDate(importDetailsForm.due_date)"
                      v-on="on" v-bind="attrs"
                      @click:clear="importDetailsForm.due_date = null"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    no-title
                    v-model="importDetailsForm.due_date"
                    @input="importDetailsForm.due_date_popup = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-text-field class="mt-2" hide-details disabled outlined xdisabled label="Turnaround"  dense :value="importDetailsForm.data.turnaround" />
              </v-col>
            </v-row>

            <v-row class="mt-2">
              <v-col cols="6">
                <LazyDropdown :url="'/jobStatus'" outlined dense v-model="importDetailsForm.status" label="Status" item-text="name" item-value="xid" />
              </v-col>
              <v-col cols="6"></v-col>
            </v-row>

            <v-card v-if="importDetailsForm.data.notes" class="mt-4">
              <v-card-text style="white-space:pre-wrap;" class="secondary--text">
                <span class="text-subtitle-2">Administrative Notes</span><br>
                <span>{{ importDetailsForm.data.notes }}</span>
              </v-card-text>
            </v-card>

          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class='primary' @click='importDetails_save'>Save</v-btn>
            <v-btn class='secondary' @click='importDetailsForm.data = null'>Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row justify='center' class='mt-4 mb-2'>
        <v-col cols=12 xl=10>
          <template v-if="MODULES.some(m=>m.code == 'delete_data')">
            <v-btn text small color="red" @click="deleteJob">Delete Job</v-btn>
          </template>
        </v-col>
      </v-row>
      

      <!-- Dialogs -->
      <QmsJobPopup ref='qms' :jobId='jobId' @taskAdded='refreshHistory' />

      <!-- Bottom of Page -->
    </v-container>
</template>

<style lang="scss" scoped>
@import "@/assets/common";
.v-btn{
  max-width: 100%;
}
.v-tab--active{
  font-weight: bold;
}
</style>


<script>
import { mapActions, mapGetters } from 'vuex'
import Momentjs from 'moment';
import Moment from '@/components/Moment'
import LazyDropdown from '@/components/LazyDropdown'
import JobHistory from '@/components/JobHistory/JobHistory'
import NewJobNote from '@/components/JobHistory/NewJobNote'
import JobLineitems from '@/components/JobLineitems/JobLineitems'
import JobFiles from '@/components/JobFiles/JobFiles'
import QmsJobPopup from '@/components/QMS/JobPopup'

export default {
  name: 'Job',

  components: {
    Moment, JobHistory, LazyDropdown, NewJobNote, JobLineitems, JobFiles, QmsJobPopup
  },

  data: () => ({
    customer: null,
    contact: null,
    //bill_to: null,
    ship_to: null,
    jobref: null,
    due_date: null,
    type: null,
    description: null,
    status: null,
    recent_activity: null,
    dispatch_status: null,
    payment_status: null,
    autosave: false,
    editDetails: false,
    loading: true,
    activeTab: null,
    dueDatePickerMenu: null,
    importDetailsForm: {
      data: null,
      loading: false,
      customer: null,
      contact: null,
      ship_to: null,
      due_date: null,
      due_date_popup: null,
      description: null,
      status: null,
      job_type: null,
      log_qms: null,
    }
  }),

  computed: {
    ...mapGetters('Auth', ['LOGGED_IN', 'TOKEN', 'MODULES', 'USER']),

    jobId(){
      return this.$route.params.jobId;
    },

    contactErrorMessage(){
      if(!this.contact) return null;
      if(!this.contact.user.email) return "No email address";
      return null;
    }

  },

  watch: {
    jobId(){ this.$emit('reload') },
    customer(customer){ if(this.autosave) this.updateJob({ customer: customer?.xid || null }); },
    contact(contact){ if(this.autosave) this.updateJob({ contact: contact?.xid || null }); },
    //bill_to(location){ if(this.autosave) this.updateJob({ bill_to: location?.id || null }); },
    ship_to(location){ if(this.autosave) this.updateJob({ ship_to: location?.xid || null }); },
    description(text){ if(this.autosave) this.updateJob({ description: text || "" }); },
    status(status){ if(this.autosave) this.updateJob({ status: status?.xid || null }); },
    dispatch_status(status){ if(this.autosave) this.updateJob({ dispatch_status: status.xid }); },
    payment_status(status){ if(this.autosave) this.updateJob({ payment_status: status.xid }); },
    due_date(date_due){ if(this.autosave) this.updateJob({ date_due }) },
    type(job_type){ if(this.autosave) this.updateJob({ job_type: job_type?.xid || null }) },
  },

  methods:{
    ...mapActions('UI', ['SHOW_NAVBARS', 'NOTIFY', 'SET_PAGE_TITLE', 'PROMPT', 'NAVIGATE', 'CONFIRM']),
    ...mapActions('Auth', ['REQUIRE_LOGIN']),
    ...mapActions('API', ['API']),
    ...mapActions('PRINTING', ['PRINT']),

    loadJob(){
      this.autosave = false;
      this.loading = true;
      return this.API({ method: 'GET', endpoint: `jobs/${this.jobId}` })
      .then(job => {
        this.customer = job.customer;
        this.contact = job.contact;
        //this.bill_to = job.bill_to;
        this.ship_to = job.ship_to;
        this.description = job.description;
        this.jobref = job.ref;
        this.status = job.status;
        this.recent_activity = job.recent_activity;
        this.dispatch_status = job.dispatch_status;
        this.payment_status = job.payment_status;
        this.due_date = job.date_due ? Momentjs(job.date_due).format('YYYY-MM-DD') : null;
        this.type = job.job_type;
        this.SET_PAGE_TITLE(this.jobref);
      })
      .finally(() => {
        this.autosave = true;
        this.loading = false;
      });
    },

    updateJob(changes){
      this.loading = true;
      return this.API({ method: 'PATCH', endpoint: `jobs/${this.jobId}`, data: changes })
      .finally(() => {
        this.$refs.lineitems.loadData();
        this.loadJob();
        this.refreshHistory();
      });
    },

    refreshHistory(){
      this.$refs['history'].refresh();
    },

    async importDetails(data){
      
      let due_date = new Momentjs();
      let turnaround = data.turnaround.match(/^.*?(\d+)(?:[)\x2A\x5D ].*)?$/);
      turnaround = turnaround ? turnaround[1] : 0;
      turnaround -= 1; // first day of turnaround is the current day
      for(let i=0; i < turnaround; i+= 1){
        due_date.add(1, 'days');
        if(due_date.day() == 6) due_date.add(2, 'days');
        if(due_date.day() == 0) due_date.add(1, 'days');
      }
      this.importDetailsForm = {
        ...this.importDetailsForm, loading: true,
        data,
        customer:null, contact: null, ship_to: null, status: null, job_type: null,
        description: this.description ? this.description : data.clientName || data.lasercam_job_number,
        due_date: due_date.format('YYYY-MM-DD'),
        log_qms: null,
      };
      // load default status from workflow settings
      let workflow = await this.API({ method: 'GET', endpoint: 'account/workflow' });
      this.importDetailsForm.status =  workflow.import_status;
      this.importDetailsForm.log_qms =  workflow.import_step;
      // pick correct job type from script type
      let job_types = await this.API({ method: 'GET', endpoint: 'job_types' });
      let job_type_map = {
        'EVA': job_types.find(t=>t.name=="Machined EVA") ?? null,
        '3DP': job_types.find(t=>t.name=="3D Printed") ?? null,
        'VAC': job_types.find(t=>t.name=="Vacuum Formed") ?? null
      };
      this.importDetailsForm.job_type = job_type_map[data.type] ?? null;
      // done
      this.importDetailsForm.loading = false;
    },

    importDetails_changeCustomer(){
      this.importDetailsForm = {
        ...this.importDetailsForm,
        contact: null, ship_to: null,
      };
    },

    importDetails_save(){
      this.importDetailsForm.loading = true;
      console.log(this.importDetailsForm.job_type);
      this.updateJob({
        description: this.importDetailsForm.description,
        customer: this.importDetailsForm.customer?.xid ?? null,
        contact: this.importDetailsForm.contact?.xid ?? null,
        ship_to: this.importDetailsForm.ship_to?.xid ?? null,
        date_due: this.importDetailsForm.due_date,
        status: this.importDetailsForm.status?.xid ?? null,
        job_type: this.importDetailsForm.job_type?.xid ?? null,
      })
      .then(() => {
        if(!this.importDetailsForm.log_qms) return;
        let data = {
          job: this.jobId,
          task: this.importDetailsForm.log_qms?.xid,
          delegate: this.USER.xid,
        };
        return this.API({ method: 'POST', endpoint: 'qms/activity', data })
        .then(() => {
          this.loadJob();
          this.refreshHistory();
        });
      })
      .finally(() => {
        this.importDetailsForm.data = null;
        this.importDetailsForm.loading = false;
      });
    },

    formatDate(date){
      if(!date) return "";
      let moment = new Momentjs(date);
      return moment.format('DD/MM/YYYY');
    },

    refreshLineItems(){
      this.$refs.lineitems.loadData();
    },

    clickQMS(){
      this.$refs.qms.open();
    },

    completeJob(){
      this.CONFIRM({ title: "Complete Job", message: "Confirm the job is completed?" })
      .then(() => {
        this.loading = true;
        this.API({ method: 'POST', endpoint: `account/workflow/complete_job/${this.jobId}` })
        .then(() => {
          this.PRINT({ url: `${window.app.config.api_base_url}/jobs/${this.jobId}/label`, type: 'Job Label' });
          this.loadJob();
          this.refreshHistory();
        });
      });
    },

    printLabel(){
      this.PRINT({ url: `${window.app.config.api_base_url}/jobs/${this.jobId}/label`, type: 'Job Label' });
    },

    async deleteJob(){
      await this.CONFIRM({ title: "Delete Job", message: "Are you sure?" });
      this.API({ method: 'DELETE', endpoint: `jobs/${this.jobId}` })
      .then(() => {
        this.NAVIGATE('/jobs');
      });
    }

  },

  mounted() {
    this.REQUIRE_LOGIN(()=>{
      this.SHOW_NAVBARS();
      this.SET_PAGE_TITLE("Job");
      this.loadJob()
      .then(()=>{
        this.autosave = true;
      });
    });
  },

};
</script>
