var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "position": "relative",
      "height": "100%"
    }
  }, [_c('FileDropper', {
    ref: "fileDropper",
    staticClass: "px-3",
    attrs: {
      "endpoint": "file/upload/job/".concat(_vm.jobId)
    },
    on: {
      "uploaded": _vm.refresh
    }
  }), _c('div', {
    staticClass: "px-8 py-2",
    staticStyle: {
      "overflow-x": "auto",
      "position": "relative"
    },
    on: {
      "dragenter": _vm.dragEnter
    }
  }, [_vm.files.length ? [_c('v-simple-table', {
    attrs: {
      "dense": ""
    }
  }, [_c('tbody', _vm._l(_vm.files, function (file) {
    return _c('tr', {
      key: file.xid,
      staticStyle: {
        "height": "auto"
      },
      attrs: {
        "xclass": "py-1 d-flex align-center fileRow"
      }
    }, [_c('td', {
      staticClass: "pl-0"
    }, [_c('div', {
      staticClass: "d-flex align-center"
    }, [_c('FileIcon', {
      staticClass: "float-left mr-2",
      attrs: {
        "file": file,
        "height": 26,
        "width": 26
      }
    }), _c('span', {
      staticClass: "text-caption"
    }, [_vm._v(_vm._s(file.filename))]), _c('span', {
      staticClass: "ml-2 text-caption secondary--text font-weight-light"
    }, [_c('Moment', {
      attrs: {
        "timestamp": file.created_at,
        "mode": "timeAgo",
        "tooltip": ""
      }
    })], 1)], 1)]), _c('td', {
      staticClass: "text-right"
    }, [file.script ? [_c('v-btn', {
      staticClass: "ml-1 primary--text",
      attrs: {
        "fab": "",
        "x-small": "",
        "text": "",
        "title": "Update Job Details"
      },
      on: {
        "click": function click($event) {
          return _vm.updateJobDetails(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-briefcase-account")])], 1), _c('v-btn', {
      staticClass: "ml-1 primary--text",
      attrs: {
        "fab": "",
        "x-small": "",
        "text": "",
        "title": "Auto-Bill"
      },
      on: {
        "click": function click($event) {
          return _vm.autoBill(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-currency-usd")])], 1), file.script.hasPDF ? _c('v-btn', {
      staticClass: "ml-1 primary--text",
      attrs: {
        "fab": "",
        "x-small": "",
        "text": "",
        "title": "View Script"
      },
      on: {
        "click": function click($event) {
          return _vm.pdf(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-file-document")])], 1) : _vm._e(), file.script.hasPDF ? _c('v-btn', {
      staticClass: "ml-1 primary--text",
      attrs: {
        "fab": "",
        "x-small": "",
        "text": "",
        "title": "Print Script"
      },
      on: {
        "click": function click($event) {
          return _vm.print_pdf(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-printer")])], 1) : _vm._e(), file.script.hasScans ? _c('v-btn', {
      staticClass: "ml-1 primary--text",
      attrs: {
        "fab": "",
        "x-small": "",
        "text": "",
        "title": "View Scans"
      },
      on: {
        "click": function click($event) {
          return _vm.scans(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-rotate-3d")])], 1) : _vm._e(), file.script.hasScans ? _c('v-btn', {
      staticClass: "ml-1 primary--text",
      attrs: {
        "fab": "",
        "x-small": "",
        "text": "",
        "title": "Start Modelling"
      },
      on: {
        "click": function click($event) {
          return _vm.start_modelling(file);
        }
      }
    }, [_c('v-icon', [_vm._v("$lasercam")])], 1) : _vm._e()] : _vm._e(), _c('v-btn', {
      staticClass: "ml-1 primary--text",
      attrs: {
        "fab": "",
        "x-small": "",
        "text": "",
        "title": "Download File"
      },
      on: {
        "click": function click($event) {
          return _vm.download(file);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-download")])], 1)], 2)]);
  }), 0)])] : [_c('div', {
    staticClass: "text-caption font-italic secondary--text"
  }, [_vm._v("This job has no files.")])]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }