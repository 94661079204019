var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-timeline-item', {
    attrs: {
      "color": _vm.colour,
      "small": ""
    }
  }, [_c('ItemHeader', {
    attrs: {
      "timestamp": _vm.timestamp,
      "user": _vm.user
    }
  }), _vm.staff_user ? [_c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" set the contact as ")]), _c('span', {
    staticClass: "text-caption font-weight-medium"
  }, [_vm._v(_vm._s(_vm.name))])] : _c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" removed the customer contact")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }