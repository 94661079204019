<template>
  <v-timeline-item :color="colour" small>
      <ItemHeader :timestamp="timestamp" />
      <div class='text-caption'><span class='font-weight-medium'>{{task}}</span> assigned to <span class='font-weight-medium'>{{delegate}}</span>.</div>
  </v-timeline-item>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
a{
  &:hover{
      xtext-decoration: underline;
  }
}
</style>

<script>
import { mapActions } from 'vuex'
import ItemHeader from './ItemHeader'

export default {
  name: 'Contact',

  components: {
    ItemHeader,
  },

  props: {
    data: { required: true },
    timestamp: { type: String, required: true },
    user: { type: Object, required: true },
  },

  data: () => ({
    colour: "primary"
  }),

  computed: {

    task(){
      return this.data?.task?.title || null;
    },

    delegate(){
      return this.data?.delegate || null;
    },

  },

  methods:{
    ...mapActions("UI", ["NAVIGATE"]),
  },

  mounted() {
  },

};
</script>
