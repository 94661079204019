var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-timeline-item', {
    attrs: {
      "color": _vm.colour,
      "small": ""
    }
  }, [_c('ItemHeader', {
    attrs: {
      "timestamp": _vm.timestamp,
      "user": _vm.user
    }
  }), _vm.location ? [_c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" set the delivery location to ")]), _c('a', {
    staticClass: "text-caption font-weight-medium",
    on: {
      "click": function click($event) {
        return _vm.showLocation(_vm.location);
      }
    }
  }, [_vm._v(_vm._s(_vm.location.company_name))])] : _c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" removed the delivery location")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }