var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-timeline-item', {
    attrs: {
      "color": _vm.colour,
      "small": ""
    }
  }, [_c('ItemHeader', {
    attrs: {
      "timestamp": _vm.timestamp,
      "user": _vm.user
    }
  }), _vm.status ? [_c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" changed the job status to ")]), _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-chip', _vm._g({
          staticClass: "px-2 white--text font-weight-bold",
          attrs: {
            "small": "",
            "label": "",
            "color": _vm.colour
          }
        }, on), [_vm._v(_vm._s(_vm.status))])];
      }
    }], null, false, 3227782111)
  }, [_vm.description === "" ? _c('span', {
    staticClass: "font-italic"
  }, [_vm._v("No description")]) : _c('span', [_vm._v(_vm._s(_vm.description))])])] : _c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" removed the job status")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }