<template>
  <div style='position:relative;height: 100%;'>
    <FileDropper @uploaded='refresh' :endpoint='`file/upload/job/${jobId}`' ref='fileDropper' class='px-3' />
    <div class='px-8 py-2' style='overflow-x:auto;position:relative;' @dragenter='dragEnter'>
      <template v-if='files.length'>

        <v-simple-table dense>
          <tbody>

        <tr v-for='file in files' v-bind:key='file.xid' style='height:auto;' xclass='py-1 d-flex align-center fileRow'>
          <td class='pl-0'>
            <div class='d-flex align-center'>
          <FileIcon :file='file' :height='26' :width='26' class='float-left mr-2' />
          <span class='text-caption'>{{file.filename}}</span>
          <span class='ml-2 text-caption secondary--text font-weight-light'><Moment :timestamp='file.created_at' mode='timeAgo' tooltip /></span>
          </div>
        </td>
        <td class='text-right'>
          <template v-if='file.script'>
            <v-btn fab x-small text class='ml-1 primary--text' @click='updateJobDetails(file)' title='Update Job Details'><v-icon>mdi-briefcase-account</v-icon></v-btn>
            <v-btn fab x-small text class='ml-1 primary--text' @click='autoBill(file)' title='Auto-Bill'><v-icon>mdi-currency-usd</v-icon></v-btn>
            <v-btn fab x-small text class='ml-1 primary--text' v-if='file.script.hasPDF' @click='pdf(file)' title='View Script'><v-icon>mdi-file-document</v-icon></v-btn>
            <v-btn fab x-small text class='ml-1 primary--text' v-if='file.script.hasPDF' @click='print_pdf(file)' title='Print Script'><v-icon>mdi-printer</v-icon></v-btn>
            <v-btn fab x-small text class='ml-1 primary--text' v-if='file.script.hasScans' @click='scans(file)' title='View Scans'><v-icon>mdi-rotate-3d</v-icon></v-btn>
            <v-btn fab x-small text class='ml-1 primary--text' v-if='file.script.hasScans' @click='start_modelling(file)' title='Start Modelling'><v-icon>$lasercam</v-icon></v-btn>
          </template>
          <v-btn fab x-small text class='ml-1 primary--text' @click='download(file)' title='Download File'><v-icon>mdi-download</v-icon></v-btn>
          </td>
        </tr>

        </tbody>
        </v-simple-table>

      </template>
      <template v-else>
        <div class='text-caption font-italic secondary--text'>This job has no files.</div>
      </template>
    </div>
  </div>
</template>


<style lang="scss">
@import "@/assets/common";
.fileRow{
  padding: 3px;
  border-radius: 3px;
  &:hover{
    background-color: rgba($secondary, 0.15);
  }
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import FileIcon from '@/components/FileIcon'
import FileDropper from '@/components/FileDropper'
import Moment from '@/components/Moment'

export default {
  name: 'JobFiles',

  components: {
    FileIcon, FileDropper, Moment
  },

  props: {
    jobId: { type: String, required: true },
  },

  data: () => ({
    loading: true,
    files: [],
  }),

  computed: {
    ...mapGetters("API", ["API_BASE"]),
  },

  methods:{
    ...mapActions("API", ["API"]),
    ...mapActions('UI', ['CONFIRM', 'NOTIFY']),
    ...mapActions('PRESCRIBE', ['PRESCRIBE_COMMAND']),
    ...mapActions('PRINTING', ['PRINT']),

    refresh(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: `jobs/${this.jobId}/files` })
      .then(files => { this.files = files; })
      .finally(() => { this.loading = false; });
    },

    download(file){
      window.open(`${this.API_BASE}/file/${file.xid}/${file.check_sha256}` ,'_blank');
    },

    pdf(file){
      window.open(`${this.API_BASE}/file/${file.xid}/${file.check_sha256}/pdf` ,'_blank');
    },

    print_pdf(file){
      this.NOTIFY({ message: "Printing..." });
      this.PRINT({ url: `${this.API_BASE}/file/${file.xid}/${file.check_sha256}/pdf`, type: 'Document' });
    },

    scans(file){
      let url = `${this.API_BASE}/file/${file.xid}/${file.check_sha256}`;
      url = encodeURIComponent(btoa(url));
      this.PRESCRIBE_COMMAND({ message: "Opening 3D Scans...", command: `viewscans/${url}` });
    },

    start_modelling(file){
      let url = `${this.API_BASE}/file/${file.xid}/${file.check_sha256}`;
      url = encodeURIComponent(btoa(url));
      this.PRESCRIBE_COMMAND({ message: "Modelling...", command: `model/${url}` });
    },

    dragEnter(e) { this.$refs.fileDropper.dragEnter(e); },

    updateJobDetails(file){
      console.log(file);
      this.$emit('updateJobDetails', file.script);
    },

    autoBill(file){
      let autobill = () => {
        return this.API({ method: 'POST', endpoint: `jobs/${this.jobId}/files/${file.xid}/auto_bill` })
        .then(() => { this.$emit('updateLineItems'); });
      };

      this.CONFIRM({ title: 'Import Billable Items', message: 'This action will replace any currently billed items. Continue?' })
      .then(autobill)

    }

  },

  mounted() {
    this.refresh();
  },

};
</script>
