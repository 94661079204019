<template>
  <v-dialog :value='isOpen' max-width="600" scrollable persistent>
    <v-card v-if='Package!=null'>
      <v-card-title class='primary white--text'>
        Package
      </v-card-title>
      <v-card-text style='max-height:80vh;height:auto;' class='pt-4'>

        <v-row>
          <v-col cols=12>
            <v-row>
              <v-col cols="12 pb-0 text-caption">
                Destination
                <v-divider class='pb-0' />
              </v-col>

              <v-col cols="12" class="secondary--text" style="white-space:pre-wrap;">{{ Package?.to_address?.address }}</v-col>

              <v-col cols="12 pb-0 text-caption">
                Package
                <v-divider class='pb-0' />
              </v-col>
              <v-col cols="12" class="secondary--text">
                <span v-if="Package.description !== null">{{ Package.description }}<br/></span>
                {{ Package.length }}cm x {{ Package.width }}cm x {{ Package.height }}cm
                <span v-if="Package.weight !== null">, {{ Package.weight }}kg</span>
              </v-col>

              <v-col cols="12 pb-0 text-caption">
                Label
                <v-divider class='pb-0' />
              </v-col>
              <v-col cols="12" class="secondary--text mb-0 pb-0"  v-if="Package.courier !== null">
                <span>{{ Package.courier.label }}</span>
              </v-col>
              <v-col cols="12" class="secondary--text mt-0 pt-0" v-if="Package.tracking_number !== null">
                <span >{{ Package.tracking_number }}</span>
              </v-col>

              <v-col cols="12 pb-0 text-caption">
                Jobs
                <v-divider class='pb-0' />
              </v-col>

              <v-col cols=12>
                <v-chip
                  v-for='j in Package.jobs' :key='j.xid'
                  label small class='primary mr-1 mb-1'
                >
                  {{j.ref}}
                </v-chip>
              </v-col>

            </v-row>
          </v-col>
        </v-row>


      </v-card-text>
      <v-divider />
      <v-card-actions class='grey lighten-4'>
        <v-spacer />
        <v-btn class='primary' @click='printLabel'>Print Label</v-btn>
        <v-btn class='secondary' @click='isOpen=false'>Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
//import { mapActions } from 'vuex'
export default {
  name: 'ViewPackageDialog',

  components: {
  },

  data: () => ({
    isOpen: false,
    Package: null,
  }),

  computed: {
  },

  methods:{
    edit(Package){
      this.Package = Package;
       this.isOpen = true;
    },


    printLabel(Package){
      this.API({ method: 'GET', endpoint: `despatch/packages/${Package.xid}/address_label` })
      .then(pdf => {
        this.PRINT({ data: pdf, type: 'Job Label'  });
      });
    }

  },

  mounted() {
  },

};
</script>
