<template>
    <v-autocomplete class='py-0 my-0'
    v-model="model"
    :items="listItems"
    :loading="loading ? 'white' : false"
    :item-text="itemText"
    :item-value="itemValue"
    return-object
    :clearable='clearable'
    hide-details
    :outlined="outlined"
    :dense="dense"
    :placeholder="placeholder"
    :label="label"
    @change='update'
    >
      <template #selection="{item}"><slot name='selection' v-bind="{item}">{{ typeof itemText === 'function' ? itemText(item) : item[itemText] }}</slot></template>
      <template #item="{item}"><slot name='item' v-bind="{item}">{{ typeof itemText === 'function' ? itemText(item) : item[itemText] }}</slot></template>
    </v-autocomplete>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'LazyDropdown',

  components: {
  },

  props: {
    url: String,
    value: Object,
    placeholder: String,
    label: String,
    outlined: Boolean,
    dense: Boolean,
    itemText: {},
    itemValue: {},
    clearable: { type: Boolean, required: false, default: true },
  },

  data: () => ({
    model: null,
    loadedItems: [],
    loading: false,
  }),

  computed: {
    listItems(){
      let listItems = [...this.loadedItems];
      if(this.value != null) listItems.push(this.value);
      return listItems;
    }
  },

  watch: {
    value(){
      this.useDefaultValue();
    },
    url(){
      this.loadList();
    },
  },

  methods:{
    ...mapActions('API', ['API']),

    loadList(){
      if(!this.url) return;
      this.loading = true;
      this.API({ method: "Get", endpoint: this.url })
      .then(loadedItems => {
        this.loadedItems = loadedItems;
        this.loading = false;
      });
    },

    refresh(){ this.loadList(); },

    useDefaultValue(){
      if(this.value !== undefined) this.model = this.value;
    },

    update(){
      this.$emit('input', this.model);
    },

  },

  mounted() {
    this.useDefaultValue();
    this.loadList();
  },

};
</script>
