<template>
  <v-timeline-item :color="colour" small>
      <ItemHeader :timestamp="timestamp" :user="user" />
      <template v-if="location">
        <span class='text-caption'> set the delivery location to </span>
        <a class='text-caption font-weight-medium' @click='showLocation(location)'>{{location.company_name}}</a>
      </template>
      <span v-else class='text-caption'> removed the delivery location</span>
  </v-timeline-item>
</template>

<style lang="scss" scoped>
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
import ItemHeader from './ItemHeader'

export default {
  name: 'DeliveryLocation',

  components: {
    ItemHeader,
  },

  props: {
    data: { required: true },
    timestamp: { type: String, required: true },
    user: { type: Object, required: true },
  },

  data: () => ({
    colour: "primary"
  }),

  computed: {

    location(){
      return this.data?.location || null;
    },

  },

  methods:{
    ...mapActions("UI", ["NAVIGATE", "ALERT"]),

    showLocation(location){
      let l = location;
      let addr = `${l.address_line_1}\n`;
      if(l.address_line_2) addr += `${l.address_line_2}\n`;
      addr += `${l.suburb} ${l.state} ${l.postcode}\n`;
      addr += l.country;
      this.ALERT({ message: addr, title: location.name });
    }

  },

  mounted() {
  },

};
</script>
