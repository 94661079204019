<template>
  <v-timeline dense class='mb-3' v-if='history.length'>
    <div v-for="item in history" v-bind:key="item.xid">
      <Status v-if="item.type == 'JobHistoryStatus'"   :data="item.data" :timestamp="item.created_at" :user="item.user" />
      <Note v-if="item.type == 'JobHistoryNote'"       :data="item.data" :timestamp="item.created_at" :user="item.user" />
      <Customer v-if="item.type == 'JobHistoryCustomer'"       :data="item.data" :timestamp="item.created_at" :user="item.user" />
      <Contact v-if="item.type == 'JobHistoryContact'"       :data="item.data" :timestamp="item.created_at" :user="item.user" />
      <DeliveryLocation v-if="item.type == 'JHDeliveryLocation'"       :data="item.data" :timestamp="item.created_at" :user="item.user" />
      <Activity v-if="item.type == 'Activity'" :data='item.data' :timestamp='item.created_at' :user='item.user' />
      <DespatchPackage v-if="item.type == 'JobHistoryDespatch'"  :data="item.data" :timestamp="item.created_at" :user="item.user" />
    </div>

    <!--
    <v-timeline-item color="primary" small>
        <span class='text-caption font-italic text--disabled'>1 Hour ago</span><br/>
        <span class='text-caption'>File Attached </span>
        <v-chip small color='primary' class='px-2'>CUSTOMER12340004_SURNAME_FIRSTNAME.zip</v-chip>
    </v-timeline-item>
    -->

  </v-timeline>
</template>

<style lang="scss">
//@import "@/assets/common";
.v-timeline{
  $dotWidth: 50px;
  .v-timeline-item__divider{ min-width: $dotWidth; }
  .v-timeline-item__body{ max-width: calc(100% - #{$dotWidth}); }
  &::before{ left: ($dotWidth/2 - 1px) !important; }
}
</style>

<script>
import { mapActions } from 'vuex'

import Status from './Status'
import Note from './Note'
import Customer from './Customer'
import Contact from './Contact'
import DeliveryLocation from './DeliveryLocation'
import Activity from './Activity'
import DespatchPackage from './DespatchPackage'

export default {
  name: 'JobHistory',

  components: {
    Status, Note, Customer, Contact, DeliveryLocation, Activity, DespatchPackage
  },

  props: {
    jobId: { type: String },
  },

  data: () => ({
    history: [],
  }),

  computed: {
  },

  methods:{
    ...mapActions('API', ['API']),

    loadHistory(){
      this.API({ method: 'GET', endpoint: `jobHistory/${this.jobId}` })
      .then(history => {
        this.history = history;
      });
    },

    refresh(){
      this.loadHistory();
    },

  },

  mounted() {
    this.loadHistory();
  },

};
</script>
