<template>
  <span class='text-caption'>
    <Moment :timestamp="timestamp" mode="calendar" tooltip tooltip-type="datetime" />
    <span v-if="user">
      <span> - </span>
      <v-tooltip bottom>
        <template v-slot:activator="{on}">
        <span class='font-weight-medium' v-on="on">{{user.name_first}} {{user.name_last}}</span>
        </template>
        {{user.username}}
      </v-tooltip>
     </span>
  </span>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>

import Moment from '../Moment'

export default {
  name: 'ItemHeader',

  components: {
    Moment,
  },

  props: {
    timestamp: { type: String, required: true },
    user: { type: Object, required: false, default: null },
  },

  data: () => ({
  }),

  computed: {
  },

  methods:{
  },

  mounted() {
  },

};
</script>
