var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-timeline-item', {
    attrs: {
      "color": _vm.colour,
      "small": ""
    }
  }, [_c('ItemHeader', {
    attrs: {
      "timestamp": _vm.timestamp
    }
  }), _c('div', {
    staticClass: "text-caption"
  }, [_c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.task))]), _vm._v(" assigned to "), _c('span', {
    staticClass: "font-weight-medium"
  }, [_vm._v(_vm._s(_vm.delegate))]), _vm._v(".")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }