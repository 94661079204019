var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-timeline-item', {
    attrs: {
      "color": _vm.data.colour,
      "small": ""
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "py-2 white--text",
    class: _vm.data.colour
  }, [_c('ItemHeader', {
    attrs: {
      "timestamp": _vm.timestamp,
      "user": _vm.user
    }
  })], 1), _c('v-card-text', {
    staticClass: "my-0 py-4 black--text white",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.text) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }