var render = function () {
  var _vm$type;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.LOGGED_IN ? _c('v-container', {
    attrs: {
      "id": "page"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('v-card', {
    attrs: {
      "loading": _vm.loading ? 'white' : false,
      "disabled": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text pt-0 pb-1"
  }, [_c('v-row', {
    staticClass: "my-0"
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-input', {
    attrs: {
      "prepend-icon": "mdi-briefcase-variant",
      "hide-details": "",
      "dark": ""
    }
  }, [_c('div', {
    staticClass: "ma-0",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.jobref))]), _vm.description && !_vm.editDetails ? _c('span', [_vm._v(": " + _vm._s(_vm.description))]) : _vm._e(), _vm.editDetails ? _c('v-text-field', {
    attrs: {
      "dense": "",
      "block": "",
      "value": _vm.description,
      "placeholder": "Job Description",
      "loading": _vm.loading ? "white" : false,
      "counter": "",
      "maxlength": "255"
    },
    on: {
      "change": function change(val) {
        _vm.description = val;
      }
    }
  }) : _vm._e(), _vm.editDetails ? _c('LazyDropdown', {
    attrs: {
      "url": "job_types",
      "placeholder": "Job Type",
      "item-text": "name",
      "item-value": "xid"
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "text-caption"
  }, [_vm._v(_vm._s((_vm$type = _vm.type) === null || _vm$type === void 0 ? void 0 : _vm$type.name))])], 1)])], 1), _c('v-col', {
    staticClass: "pt-2 pr-0 pb-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('div', [_c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "fab": "",
      "small": "",
      "dark": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.editDetails = !_vm.editDetails;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.editDetails ? 'mdi-lock-open' : 'mdi-lock'))])], 1), _c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "fab": "",
      "small": "",
      "dark": "",
      "text": "",
      "title": "Print Job Label"
    },
    on: {
      "click": _vm.printLabel
    }
  }, [_c('v-icon', [_vm._v("mdi-tag-text")])], 1), _c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "fab": "",
      "small": "",
      "dark": "",
      "text": "",
      "title": "Quality Management System"
    },
    on: {
      "click": _vm.clickQMS
    }
  }, [_c('v-icon', [_vm._v("mdi-medal-outline")])], 1), _c('v-btn', {
    staticClass: "float-right",
    attrs: {
      "fab": "",
      "small": "",
      "dark": "",
      "text": "",
      "title": "Complete Job"
    },
    on: {
      "click": _vm.completeJob
    }
  }, [_c('v-icon', [_vm._v("mdi-file-check-outline")])], 1)], 1)])], 1)], 1), _c('v-card-text', {
    staticClass: "xprimary xwhite--text text-body-1 py-0"
  }, [_c('v-row', {
    staticClass: "my-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-input', {
    attrs: {
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-office-building")])];
      },
      proxy: true
    }], null, false, 3628054057)
  }, [_vm.editDetails == false ? [_vm.customer ? _c('span', {
    staticClass: "text-button text-truncate",
    staticStyle: {
      "max-width": "100%",
      "cursor": "pointer"
    },
    on: {
      "click": function click($event) {
        return _vm.NAVIGATE('/customer/' + _vm.customer.xid);
      }
    }
  }, [_vm._v(_vm._s(_vm.customer.name))]) : _c('span', {
    staticClass: "text-button text-truncate grey--text",
    staticStyle: {
      "max-width": "100%"
    }
  }, [_vm._v("Customer")])] : [_c('LazyDropdown', {
    attrs: {
      "url": "customers",
      "placeholder": "Customer...",
      "item-text": "name",
      "item-value": "xid"
    },
    model: {
      value: _vm.customer,
      callback: function callback($$v) {
        _vm.customer = $$v;
      },
      expression: "customer"
    }
  })]], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-input', {
    attrs: {
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-account-circle")])];
      },
      proxy: true
    }], null, false, 3256715660)
  }, [_c('div', {}, [_vm.editDetails == false ? [_vm.contact ? _c('div', {
    staticClass: "text-button text-truncate",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "text": ""
    }
  }, [_vm._v(_vm._s(_vm.contact.user.name_first) + " " + _vm._s(_vm.contact.user.name_last))]) : _c('span', {
    staticClass: "text-button text-truncate grey--text",
    staticStyle: {
      "max-width": "100%"
    }
  }, [_vm._v("Contact")])] : [_vm.customer ? _c('LazyDropdown', {
    attrs: {
      "url": '/customers/' + _vm.customer.xid + '/staff',
      "placeholder": "Contact",
      "item-text": function itemText(_ref) {
        var user = _ref.user;
        return "".concat(user.name_first, " ").concat(user.name_last);
      },
      "xitem-value": "xid"
    },
    model: {
      value: _vm.contact,
      callback: function callback($$v) {
        _vm.contact = $$v;
      },
      expression: "contact"
    }
  }) : _vm._e()], _vm.contactErrorMessage ? _c('div', {
    staticClass: "text-caption warning--text"
  }, [_vm._v(_vm._s(_vm.contactErrorMessage))]) : _vm._e()], 2)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-input', {
    attrs: {
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-office-building-marker")])];
      },
      proxy: true
    }], null, false, 2205776582)
  }, [_vm.editDetails == false ? [_vm.ship_to ? _c('span', {
    staticClass: "text-button text-truncate",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "text": ""
    }
  }, [true ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(_vm._s(_vm.ship_to.company_name))])];
      }
    }], null, false, 2866167141)
  }, [_vm._v(" " + _vm._s(_vm.ship_to.address) + " ")]) : _vm._e()], 1) : _c('span', {
    staticClass: "text-button text-truncate grey--text",
    staticStyle: {
      "max-width": "100%"
    }
  }, [_vm._v("Delivery Address")])] : [_vm.customer ? _c('LazyDropdown', {
    attrs: {
      "url": '/customers/' + _vm.customer.xid + '/locations',
      "placeholder": "Delivery Address",
      "item-text": "company_name",
      "item-value": "xid"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', [_c('div', [_vm._v(_vm._s(item.company_name))]), _c('div', {
          staticClass: "text-caption secondary--text"
        }, [_vm._v(_vm._s([item.address_line_1, item.address_line_2, item.address_line_3].filter(function (v) {
          return v;
        }).join(', ')))]), _c('div', {
          staticClass: "text-caption secondary--text"
        }, [_vm._v(_vm._s([item.city_locality, item.state_province, item.postal_code].filter(function (v) {
          return v;
        }).join(' ')))])])];
      }
    }], null, false, 2335647006),
    model: {
      value: _vm.ship_to,
      callback: function callback($$v) {
        _vm.ship_to = $$v;
      },
      expression: "ship_to"
    }
  }) : _vm._e()]], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-input', {
    attrs: {
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-calendar")])];
      },
      proxy: true
    }], null, false, 2625924992)
  }, [_vm.editDetails == false ? [_vm.due_date ? _c('span', {
    staticClass: "text-button text-truncate",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "text": ""
    }
  }, [_c('Moment', {
    attrs: {
      "timestamp": _vm.due_date,
      "xmode": "calendar",
      "tooltip": ""
    }
  })], 1) : _c('span', {
    staticClass: "text-button text-truncate grey--text",
    staticStyle: {
      "max-width": "100%"
    }
  }, [_vm._v("Due Date")])] : [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "xtransition": "scale-transition",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "hide-details": "",
            "dense": "",
            "readonly": "",
            "clearable": "",
            "placeholder": "Due Date"
          },
          on: {
            "click:clear": function clickClear($event) {
              _vm.due_date = null;
            }
          },
          model: {
            value: _vm.due_date,
            callback: function callback($$v) {
              _vm.due_date = $$v;
            },
            expression: "due_date"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 2520440730),
    model: {
      value: _vm.dueDatePickerMenu,
      callback: function callback($$v) {
        _vm.dueDatePickerMenu = $$v;
      },
      expression: "dueDatePickerMenu"
    }
  }, [_c('v-date-picker', {
    on: {
      "input": function input($event) {
        _vm.dueDatePickerMenu = false;
      }
    },
    model: {
      value: _vm.due_date,
      callback: function callback($$v) {
        _vm.due_date = $$v;
      },
      expression: "due_date"
    }
  })], 1)]], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-input', {
    attrs: {
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-state-machine")])];
      },
      proxy: true
    }], null, false, 4292066317)
  }, [_vm.editDetails == false ? [_vm.status ? _c('span', {
    staticClass: "text-button text-truncate",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "text": ""
    }
  }, [_vm._v(_vm._s(_vm.status.name))]) : _c('span', {
    staticClass: "text-button text-truncate grey--text",
    staticStyle: {
      "max-width": "100%"
    }
  }, [_vm._v("Status")])] : [_c('LazyDropdown', {
    attrs: {
      "url": '/jobStatus',
      "placeholder": "Status",
      "item-text": "name",
      "item-value": "xid"
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })]], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-input', {
    attrs: {
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-progress-star")])];
      },
      proxy: true
    }], null, false, 2077548086)
  }, [_c('div', {
    class: {
      'secondary--text': _vm.editDetails
    }
  }, [_vm.recent_activity ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref5) {
        var on = _ref5.on,
          attrs = _ref5.attrs;
        return [_c('span', _vm._g(_vm._b({
          staticClass: "text-button text-truncate",
          staticStyle: {
            "max-width": "100%"
          }
        }, 'span', attrs, false), on), [_vm._v(_vm._s(_vm.recent_activity.task.title))])];
      }
    }], null, false, 2547697884)
  }, [_c('Moment', {
    attrs: {
      "timestamp": _vm.recent_activity.created_at,
      "mode": "timeAgo"
    }
  }), _vm._v(" by " + _vm._s(_vm.recent_activity.delegate) + " ")], 1) : _c('span', {
    staticClass: "text-button text-truncate grey--text",
    staticStyle: {
      "max-width": "100%"
    }
  }, [_vm._v("No Recent Activity")])], 1)])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-input', {
    attrs: {
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-truck")])];
      },
      proxy: true
    }], null, false, 2102510889)
  }, [_vm.editDetails == false ? [_vm.dispatch_status ? _c('span', {
    staticClass: "text-button text-truncate",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "text": ""
    }
  }, [_vm._v(_vm._s(_vm.dispatch_status.status))]) : _c('span', {
    staticClass: "text-button text-truncate grey--text",
    staticStyle: {
      "max-width": "100%"
    }
  }, [_vm._v("Dispatch Status")])] : [_c('LazyDropdown', {
    attrs: {
      "url": '/dispatch/status',
      "placeholder": "Status",
      "item-text": "status",
      "item-value": "xid",
      "clearable": false
    },
    model: {
      value: _vm.dispatch_status,
      callback: function callback($$v) {
        _vm.dispatch_status = $$v;
      },
      expression: "dispatch_status"
    }
  })]], 2)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-input', {
    attrs: {
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          }
        }, [_vm._v("mdi-currency-usd")])];
      },
      proxy: true
    }], null, false, 3324028794)
  }, [_vm.editDetails == false ? [_vm.payment_status ? _c('span', {
    staticClass: "text-button text-truncate",
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "text": ""
    }
  }, [_vm._v(_vm._s(_vm.payment_status.status))]) : _c('span', {
    staticClass: "text-button text-truncate grey--text",
    staticStyle: {
      "max-width": "100%"
    }
  }, [_vm._v("Payment Status")])] : [_c('LazyDropdown', {
    attrs: {
      "url": '/billing/status',
      "placeholder": "Status",
      "item-text": "status",
      "item-value": "xid",
      "clearable": false
    },
    model: {
      value: _vm.payment_status,
      callback: function callback($$v) {
        _vm.payment_status = $$v;
      },
      expression: "payment_status"
    }
  })]], 2)], 1)], 1)], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mb-2",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary py-1 white--text text-h6"
  }, [_c('v-icon', {
    staticClass: "mr-2 white--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-paperclip")]), _vm._v("Files ")], 1), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('jobFiles', {
    ref: "jobFiles",
    attrs: {
      "jobId": _vm.jobId
    },
    on: {
      "updateJobDetails": _vm.importDetails,
      "updateLineItems": _vm.refreshLineItems
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mb-2",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary py-1 white--text text-h6"
  }, [_c('v-icon', {
    staticClass: "mr-2 white--text",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-clipboard-text")]), _vm._v("Details")], 1), _c('v-row', {
    staticClass: "mt-2 pb-4"
  }, [_c('v-col', {
    staticClass: "px-8",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('NewJobNote', {
    attrs: {
      "jobId": _vm.jobId
    },
    on: {
      "newNote": _vm.refreshHistory
    }
  }), _c('JobHistory', {
    ref: "history",
    attrs: {
      "jobId": _vm.jobId
    }
  })], 1), _c('v-col', {
    staticClass: "px-8",
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('JobLineitems', {
    ref: "lineitems",
    attrs: {
      "jobId": _vm.jobId
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "value": _vm.importDetailsForm.data,
      "persistent": "",
      "max-width": "800px",
      "scrollable": ""
    }
  }, [_vm.importDetailsForm.data ? _c('v-card', {
    attrs: {
      "disabled": _vm.importDetailsForm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Import Job Details")]), _c('v-card-text', {}, [_c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "primary--text font-weight-medium"
  }, [_vm._v("Job Details")])]), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "primary--text font-weight-medium"
  }, [_vm._v("Prescription Details")])])], 1), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "value": _vm.importDetailsForm.description,
      "label": "Description"
    },
    on: {
      "change": function change(val) {
        _vm.importDetailsForm.description = val;
      }
    }
  }), _c('LazyDropdown', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "dense": "",
      "url": "job_types",
      "label": "Job Type",
      "item-text": "name",
      "item-value": "xid"
    },
    model: {
      value: _vm.importDetailsForm.job_type,
      callback: function callback($$v) {
        _vm.$set(_vm.importDetailsForm, "job_type", $$v);
      },
      expression: "importDetailsForm.job_type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "label": "Prescription #",
      "disabled": "",
      "dense": "",
      "value": _vm.importDetailsForm.data.lasercam_job_number
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "disabled": "",
      "label": "Client",
      "dense": "",
      "value": _vm.importDetailsForm.data.clientName
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    staticClass: "mt-2",
    attrs: {
      "outlined": "",
      "dense": "",
      "url": "customers",
      "label": "Customer",
      "item-text": "name",
      "item-value": "xid"
    },
    on: {
      "input": _vm.importDetails_changeCustomer
    },
    model: {
      value: _vm.importDetailsForm.customer,
      callback: function callback($$v) {
        _vm.$set(_vm.importDetailsForm, "customer", $$v);
      },
      expression: "importDetailsForm.customer"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "disabled": "",
      "label": "Clinic",
      "dense": "",
      "value": _vm.importDetailsForm.data.clinicName
    }
  })], 1)], 1), _vm.importDetailsForm.customer ? _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "dense": "",
      "url": '/customers/' + _vm.importDetailsForm.customer.xid + '/locations',
      "label": "Delivery Address",
      "item-text": "company_name",
      "item-value": "xid"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', [_c('div', [_vm._v(_vm._s(item.company_name))]), _c('div', {
          staticClass: "text-caption secondary--text"
        }, [_vm._v(_vm._s([item.address_line_1, item.address_line_2, item.address_line_3].filter(function (v) {
          return v;
        }).join(', ')))]), _c('div', {
          staticClass: "text-caption secondary--text"
        }, [_vm._v(_vm._s([item.city_locality, item.state_province, item.postal_code].filter(function (v) {
          return v;
        }).join(' ')))])])];
      }
    }], null, false, 2335647006),
    model: {
      value: _vm.importDetailsForm.ship_to,
      callback: function callback($$v) {
        _vm.$set(_vm.importDetailsForm, "ship_to", $$v);
      },
      expression: "importDetailsForm.ship_to"
    }
  }), _c('LazyDropdown', {
    staticClass: "mt-4",
    attrs: {
      "outlined": "",
      "dense": "",
      "url": '/customers/' + _vm.importDetailsForm.customer.xid + '/staff',
      "label": "Contact",
      "item-text": function itemText(_ref7) {
        var user = _ref7.user;
        return "".concat(user.name_first, " ").concat(user.name_last);
      }
    },
    model: {
      value: _vm.importDetailsForm.contact,
      callback: function callback($$v) {
        _vm.$set(_vm.importDetailsForm, "contact", $$v);
      },
      expression: "importDetailsForm.contact"
    }
  })], 1), _c('v-col', {
    staticClass: "pt-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "disabled": "",
      "dense": "",
      "label": "Address",
      "value": _vm.importDetailsForm.data.clinicAddress
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "hide-details": "",
      "outlined": "",
      "disabled": "",
      "dense": "",
      "label": "Clinician",
      "value": _vm.importDetailsForm.data.clinicianName
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    attrs: {
      "offset-y": "",
      "min-width": "auto",
      "max-width": "290px",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref8) {
        var on = _ref8.on,
          attrs = _ref8.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2",
          attrs: {
            "readonly": "",
            "clearable": "",
            "outlined": "",
            "dense": "",
            "label": "Due Date",
            "hide-details": "",
            "value": _vm.formatDate(_vm.importDetailsForm.due_date)
          },
          on: {
            "click:clear": function clickClear($event) {
              _vm.importDetailsForm.due_date = null;
            }
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 533852044),
    model: {
      value: _vm.importDetailsForm.due_date_popup,
      callback: function callback($$v) {
        _vm.$set(_vm.importDetailsForm, "due_date_popup", $$v);
      },
      expression: "importDetailsForm.due_date_popup"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": ""
    },
    on: {
      "input": function input($event) {
        _vm.importDetailsForm.due_date_popup = false;
      }
    },
    model: {
      value: _vm.importDetailsForm.due_date,
      callback: function callback($$v) {
        _vm.$set(_vm.importDetailsForm, "due_date", $$v);
      },
      expression: "importDetailsForm.due_date"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-2",
    attrs: {
      "hide-details": "",
      "disabled": "",
      "outlined": "",
      "xdisabled": "",
      "label": "Turnaround",
      "dense": "",
      "value": _vm.importDetailsForm.data.turnaround
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('LazyDropdown', {
    attrs: {
      "url": '/jobStatus',
      "outlined": "",
      "dense": "",
      "label": "Status",
      "item-text": "name",
      "item-value": "xid"
    },
    model: {
      value: _vm.importDetailsForm.status,
      callback: function callback($$v) {
        _vm.$set(_vm.importDetailsForm, "status", $$v);
      },
      expression: "importDetailsForm.status"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  })], 1), _vm.importDetailsForm.data.notes ? _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-text', {
    staticClass: "secondary--text",
    staticStyle: {
      "white-space": "pre-wrap"
    }
  }, [_c('span', {
    staticClass: "text-subtitle-2"
  }, [_vm._v("Administrative Notes")]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.importDetailsForm.data.notes))])])], 1) : _vm._e()], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.importDetails_save
    }
  }, [_vm._v("Save")]), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.importDetailsForm.data = null;
      }
    }
  }, [_vm._v("Close")])], 1)], 1) : _vm._e()], 1), _c('v-row', {
    staticClass: "mt-4 mb-2",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xl": "10"
    }
  }, [_vm.MODULES.some(function (m) {
    return m.code == 'delete_data';
  }) ? [_c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "color": "red"
    },
    on: {
      "click": _vm.deleteJob
    }
  }, [_vm._v("Delete Job")])] : _vm._e()], 2)], 1), _c('QmsJobPopup', {
    ref: "qms",
    attrs: {
      "jobId": _vm.jobId
    },
    on: {
      "taskAdded": _vm.refreshHistory
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }