var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "mb-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "counter": _vm.hasText,
      "hide-details": !_vm.hasText,
      "outlined": "",
      "auto-grow": "",
      "label": "Write a note...",
      "rows": "1",
      "maxlength": "500",
      "disabled": _vm.loading,
      "color": _vm.colour,
      "dense": ""
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  }), _vm.hasText ? _c('v-row', {
    staticStyle: {
      "position": "relative",
      "top": "-10px"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "color": _vm.colour,
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.addNote
    }
  }, [_vm._v("Add Note")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.hasText ? _c('v-btn-toggle', {
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.colour,
      callback: function callback($$v) {
        _vm.colour = $$v;
      },
      expression: "colour"
    }
  }, _vm._l(_vm.colours, function (c) {
    return _c('v-btn', {
      key: c,
      staticStyle: {
        "height": "38px",
        "width": "38px",
        "padding": "0px"
      },
      attrs: {
        "color": c,
        "icon": "",
        "value": c,
        "disabled": _vm.loading
      }
    }, [_c('v-icon', {
      attrs: {
        "color": c,
        "small": _vm.colour != c
      }
    }, [_vm._v("mdi-circle")])], 1);
  }), 1) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }