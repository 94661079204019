var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "value": _vm.visible,
      "persistent": "",
      "scrollable": "",
      "width": "80vw",
      "max-width": "1200px"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Quality Management System")]), _c('v-card-text', {
    staticClass: "pa-4",
    staticStyle: {
      "height": "80vh",
      "max-height": "700px"
    }
  }, [_c('v-simple-table', {
    attrs: {
      "dense": ""
    }
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Task")]), _c('th', [_vm._v("Delegate")]), _c('th', [_vm._v("Author")]), _c('th', [_vm._v("Date")])])]), _c('tbody', _vm._l(_vm.activity, function (a) {
    return _c('tr', {
      key: a.xid
    }, [_c('td', [_vm._v(_vm._s(a.task.title))]), _c('td', [_vm._v(_vm._s(a.delegate))]), _c('td', [_vm._v(_vm._s(a.author.name_first) + " " + _vm._s(a.author.name_last))]), _c('td', [_c('Moment', {
      attrs: {
        "timestamp": a.created_at,
        "mode": "calendar",
        "tooltip": ""
      }
    })], 1)]);
  }), 0)]), _c('v-btn', {
    staticClass: "primary--text",
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.assignTask
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-plus")]), _vm._v(" Assign Task")], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Close")])], 1)], 1)], 1), _c('TaskAssigner', {
    ref: "taskAssigner",
    on: {
      "taskAdded": _vm.refreshActivity
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }