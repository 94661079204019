var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-timeline-item', {
    attrs: {
      "color": _vm.colour,
      "small": ""
    }
  }, [_c('ItemHeader', {
    attrs: {
      "timestamp": _vm.timestamp,
      "user": _vm.user
    }
  }), _c('span', {
    staticClass: "text-caption"
  }, [_vm._v(" despatched this job")]), _vm._package ? [_c('span', {
    staticClass: "text-caption"
  }, [_vm._v("s ")]), _c('a', {
    staticClass: "text-caption font-weight-medium",
    on: {
      "click": function click($event) {
        return _vm.viewPackage(_vm._package);
      }
    }
  }, [_vm._v(" package")]), _c('br'), _vm._package.tracking_number ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_vm._package.tracking_url ? _c('v-btn', {
    staticClass: "overflow-hidden d-block mr-2 pa-1",
    attrs: {
      "icon": "",
      "large": ""
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.clickTrackingLink(_vm._package);
      }
    }
  }, [_c('v-img', {
    staticClass: "rounded-xl",
    staticStyle: {
      "mix-blend-mode": "multiply"
    },
    attrs: {
      "src": _vm._package.courier._17track_courier.logo,
      "contain": ""
    }
  })], 1) : _vm._e(), _c('a', {
    staticClass: "text-caption font-weight-medium",
    on: {
      "click": function click($event) {
        return _vm.copyToClipboard(_vm._package.tracking_number);
      }
    }
  }, [_vm._v(_vm._s(_vm._package.tracking_number))])], 1) : _vm._e()] : _vm._e(), _c('ViewPackageDialog', {
    ref: "viewPackageDialog"
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }